import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_MEDICALCONSULATION,
    DELETE_MEDICALCONSULATION,
    MEDICALCONSULATION_FORM_TOOGLE_LOADING,
    SET_MEDICALCONSULATION_LIST,
    SHOW_NOTIFICATION,
    UPDATE_MEDICALCONSULATION,
} from "../constants";
import { formatMedicalConsultations } from "./settingsActionsUtils";

/* MEDICALCONSULATION LIST */
export const fetchMedicalConsultations = () => {
    return async (dispatch, useState) => {
        const response = await axios
            .get(API.medicalConsultations.list)
            .then((response) => {
                const medicalConsultations = formatMedicalConsultations(response.data, useState());
                dispatch({
                    type: SET_MEDICALCONSULATION_LIST,
                    payload: keyBy(medicalConsultations, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};
//MEDICAL CONSULTATIONS BY DATE
export const fetchMedicalConsultationsByDate = (date) => {
    return async (dispatch, useState) => {
        const response = await axios
            .get(`${API.medicalConsultations.byDate}?date=${date}`)
            .then((response) => {
                const medicalConsultations = formatMedicalConsultations(response.data, useState());
                dispatch({
                    type: SET_MEDICALCONSULATION_LIST,
                    payload: keyBy(medicalConsultations, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

export const fetchMedicalConsultationsFromDate = (date) => {
    return async (dispatch, useState) => {
        const response = await axios
            .get(`${API.medicalConsultations.fromDate}?date=${date}`)
            .then((response) => {
                const medicalConsultations = formatMedicalConsultations(response.data, useState());
                dispatch({
                    type: SET_MEDICALCONSULATION_LIST,
                    payload: keyBy(medicalConsultations, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

//MEDICAL CONSULTATIONS BY doctor
export const fetchMedicalConsultationsByDoctor = (doctor) => {
    return async (dispatch, useState) => {
        const response = await axios
            .get(`${API.medicalConsultations.byDoctor}?doctor=${doctor}`)
            .then((response) => {
                const medicalConsultations = formatMedicalConsultations(response.data, useState());
                dispatch({
                    type: SET_MEDICALCONSULATION_LIST,
                    payload: keyBy(medicalConsultations, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

//MEDICAL CONSULTATIONS BY client
export const fetchMedicalConsultationsByClient = (client) => {
    return async (dispatch, useState) => {
        const response = await axios
            .get(`${API.medicalConsultations.byClient}?client=${client}`)
            .then((response) => {
                const medicalConsultations = formatMedicalConsultations(response.data, useState());
                dispatch({
                    type: SET_MEDICALCONSULATION_LIST,
                    payload: keyBy(medicalConsultations, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};
//MEDICAL CONSULTATIONS BY CONFIRM
export const fetchMedicalConsultationsByConfirm = () => {
    return async (dispatch, useState) => {
        const response = await axios
            .get(`${API.medicalConsultations.byConfirm}`)
            .then((response) => {
                const medicalConsultations = formatMedicalConsultations(response.data, useState());
                dispatch({
                    type: SET_MEDICALCONSULATION_LIST,
                    payload: keyBy(medicalConsultations, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// MedicalConsultations
export const createMedicalConsulation = (newMedicalConsulation) => {
    return async (dispatch, useState) => {
        dispatch({ type: MEDICALCONSULATION_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.medicalConsultations.create, newMedicalConsulation)
            .then((response) => {
                const medicalConsulation = formatMedicalConsultations(response.data, useState());
                dispatch({ type: ADD_NEW_MEDICALCONSULATION, payload: medicalConsulation });
                dispatch({ type: MEDICALCONSULATION_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "ConsultaMedica creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: MEDICALCONSULATION_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateMedicalConsulation = (updatedMedicalConsulation) => {
    return async (dispatch, useState) => {
        dispatch({ type: MEDICALCONSULATION_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.medicalConsultations.edit}${updatedMedicalConsulation && updatedMedicalConsulation._id}`, updatedMedicalConsulation)
            .then((response) => {
                const medicalConsulation = formatMedicalConsultations(response.data, useState());
                dispatch({ type: UPDATE_MEDICALCONSULATION, payload: medicalConsulation });
                dispatch({ type: MEDICALCONSULATION_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "ConsultaMedica actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: MEDICALCONSULATION_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteMedicalConsultations = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: MEDICALCONSULATION_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.medicalConsultations.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_MEDICALCONSULATION, payload: Ids });
                dispatch({ type: MEDICALCONSULATION_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "ConsultaMedica eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: MEDICALCONSULATION_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const addConsultationNote = (consultationId, noteData) => {

    return async (dispatch) => {

        dispatch({ type: MEDICALCONSULATION_FORM_TOOGLE_LOADING });
        try {

            const response = await axios.post(`${API.medicalConsultations.addNotes.replace(':consultationId', consultationId)}`, noteData);

            const updatedConsultation = response.data;

            dispatch({ type: UPDATE_MEDICALCONSULATION, payload: updatedConsultation });
            dispatch({ type: MEDICALCONSULATION_FORM_TOOGLE_LOADING });
            dispatch({
                type: SHOW_NOTIFICATION,
                payload: {
                    show: true,
                    status: 'info',
                    message: 'Nota agregada a la consulta médica.',
                },
            });
            return response && response.status;
        } catch (error) {

            dispatch({ type: MEDICALCONSULATION_FORM_TOOGLE_LOADING });

            dispatch({
                type: SHOW_NOTIFICATION,
                payload: {
                    show: true,
                    status: 'error',
                    message: 'Error al agregar la nota a la consulta médica.',
                },
            });

            return error;
        }
    };
};

export const modifiConsultationNote = (consultationId, noteIndex, noteData) => {
    return async (dispatch) => {

        dispatch({ type: MEDICALCONSULATION_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.medicalConsultations.editNotes.replace(':consultationId', consultationId).replace(':noteIndex', noteIndex)}`, noteData)
            .then((response) => {
                const updatedConsultation = response.data.data.consultation;
                dispatch({ type: UPDATE_MEDICALCONSULATION, payload: updatedConsultation });
                dispatch({ type: MEDICALCONSULATION_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Nota actualizada en la consulta médica.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: MEDICALCONSULATION_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "error",
                        message: "Error al actualizar la nota en la consulta médica.",
                    },
                });
                return err;
            });
        return response;
    };
};

export const deleteConsultationNote = (consultationId, noteIndex) => {
    return async (dispatch) => {
        dispatch({ type: MEDICALCONSULATION_FORM_TOOGLE_LOADING });
        try {
            const response = await axios.delete(`${API.medicalConsultations.deleteNotes.replace(':consultationId', consultationId).replace(':noteIndex', noteIndex)}`);

            const updatedConsultation = response.data.data.consultation;

            dispatch({ type: UPDATE_MEDICALCONSULATION, payload: updatedConsultation });
            dispatch({ type: MEDICALCONSULATION_FORM_TOOGLE_LOADING });
            dispatch({
                type: SHOW_NOTIFICATION,
                payload: {
                    show: true,
                    status: 'info',
                    message: 'Nota eliminada de la consulta médica.',
                },
            });
            return response && response.status;
        } catch (error) {
            dispatch({ type: MEDICALCONSULATION_FORM_TOOGLE_LOADING });

            dispatch({
                type: SHOW_NOTIFICATION,
                payload: {
                    show: true,
                    status: 'error',
                    message: 'Error al eliminar la nota de la consulta médica.',
                },
            });

            return error;
        }
    };
};

export const fetchDisbleMinuts = (day, typeMedicalConsultation) => {
    return async (dispatch) => {
        try {
            const response = await axios.get(`${API.medicalConsultations.getMinutes}?date=${day}&timeTypeMedicalConsultation=${typeMedicalConsultation}`);
            const minutes = response.data;

            return minutes;
        } catch (err) {
            console.error("Error fetching disable minutes:", err);
            throw err;
        }
    };
};

