import moment from "moment";
import { isArray, isObject } from "lodash";

export const formatEventss = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name}`,
      value: data._id,
      startDate: data.startDate && moment(data.startDate),
      endDate: data.endDate && moment(data.endDate),
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name}`,
      startDate: data.startDate && moment(data.startDate),
      endDate: data.endDate && moment(data.endDate),
    };
  }
};
export const formatConsultsNotifications = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name}`,
    };
  }
};
export const formatroom = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name}`,
    };
  }
};
export const formatTimeToConsultations = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name}`,
    };
  }
};
export const formatConfirmConsults = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name}`,
    };
  }
};
export const formatMedicalConsultations = (data, state) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${state.typeMedicalConsultations[data.typeMedicalConsultation] && state.typeMedicalConsultations[data.typeMedicalConsultation].label}`,
      value: data._id,
      date: data.date && moment(data.date),
      startConsult: data.startConsult && moment(data.startConsult),
      endConsult: data.endConsult && moment(data.endConsult),
      duration: `${state.typeMedicalConsultations[data.typeMedicalConsultation] && state.typeMedicalConsultations[data.typeMedicalConsultation].estimatedTime}`,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${state.typeMedicalConsultations[data.typeMedicalConsultation] && state.typeMedicalConsultations[data.typeMedicalConsultation].label}`,
      date: data.date && moment(data.date),
      startConsult: data.startConsult && moment(data.startConsult),
      endConsult: data.endConsult && moment(data.endConsult),
      duration: `${state.typeMedicalConsultations[data.typeMedicalConsultation] && state.typeMedicalConsultations[data.typeMedicalConsultation].estimatedTime}`,
    };
  }
};
export const formatEmployees = (data, state) => {
  if (!data) {
    return [];
  }
  if (!state) {
    return [];
  }
  if (!state.users) {
    return [];
  }

  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${state.users[data.user] && state.users[data.user].label}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${state.users[data.user] && state.users[data.user].label}`,
    };
  }
};
export const formatTypeMedicalConsultations = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name}`,
    };
  }
};
export const formatClients = (data, state) => {
  if (!data) {
    return [];
  }
  if (!state) {
    return [];
  }
  if (!state.users) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: (data.name && data.lastName) ? `${data.name} ${data.lastName}` : `${state.users[data.user] && state.users[data.user].label}`,
      value: data._id,
      bithday: data.bithday && moment(data.bithday),
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: (data.name && data.lastName) ? `${data.name} ${data.lastName}` : `${state.users[data.user] && state.users[data.user].label}`,
      bithday: data.bithday && moment(data.bithday),
    };
  }
};
export const formatMedicalHistorys = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name}`,
    };
  }
};
export const formatUsers = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name} ${data.surname}`,
      value: data._id
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name} ${data.surname}`
    };
  }
};

export const formatAlerts = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.title}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.title}`,
    };
  }
};
