/* BASIC APP CONSTANTS */
export const AUTH = "AUTH";
export const LOGOUT = "LOGOUT";
export const APP_COOKIE_NAME = "APP_COOKIE_NAME";
export const USER_COOKIE_NAME = "USER_COOKIE_NAME";
export const PASS_COOKIE_NAME = "PASS_COOKIE_NAME";
export const USER_ID_COOKIE_NAME = "USER_ID_COOKIE_NAME";
export const SET_CURRENT_ROUTE = "SET_CURRENT_ROUTE";
export const SET_SIDE_MENU_STATE = "SET_SIDE_MENU_STATE";
export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const CLOSE_NOTIFICATION = "CLOSE_NOTIFICATION";
export const SHOW_ACTION_MENU = "SHOW_ACTION_MENU";
export const HIDE_ACTION_MENU = "HIDE_ACTION_MENU";
export const SHOW_BOTTOM_TOOLBAR = "SHOW_BOTTOM_TOOLBAR";
export const HIDE_BOTTOM_TOOLBAR = "HIDE_BOTTOM_TOOLBAR";
export const SET_USER_LIST = "SET_USER_LIST";
export const EDIT_DISABLED_BUTTONS = "EDIT_DISABLED_BUTTONS";
export const SET_LOGS_LIST = "SET_LOGS_LIST";
export const THEME = "THEME";

/* <---- BASIC APP CONSTANTS ----> */

/* SOCKET CONSTANTS */
export const CONNECTED = "connected";
export const DISCONNECTED = "disconnected";
/* <---- SOCKET CONSTANTS ----> */

/* CONFIGURATION */
export const CONFIGURATION_FORM_TOOGLE_LOADING =
  "CONFIGURATION_FORM_TOOGLE_LOADING";
export const SET_CONFIGURATION = "SET_CONFIGURATION";
/* <---- CONFIGURATION ----> */

/* USER */
export const SET_PROFILE = "SET_PROFILE";
/* <---- USER ----> */

/* USERS */
export const ADD_NEW_USER = "ADD_NEW_USER";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";
export const OPEN_USERS_FORM = "OPEN_USERS_FORM";
export const CLOSE_USERS_FORM = "CLOSE_USERS_FORM";
export const EDIT_SELECTED_USER = "EDIT_SELECTED_USER";
export const USERS_FORM_TOOGLE_LOADING = "USERS_FORM_TOOGLE_LOADING";
/* <---- USERS ----> */

/* EVENTS */
export const SET_EVENTS_LIST = "SET_EVENTS_LIST";
export const ADD_NEW_EVENTS = "ADD_NEW_EVENTS";
export const UPDATE_EVENTS = "UPDATE_EVENTS";
export const DELETE_EVENTS = "DELETE_EVENTS";
export const OPEN_EVENTS_FORM = "OPEN_EVENTS_FORM";
export const CLOSE_EVENTS_FORM = "CLOSE_EVENTS_FORM";
export const EDIT_SELECTED_EVENTS = "EDIT_SELECTED_EVENTS";
export const EVENTS_FORM_TOOGLE_LOADING = "EVENTS_FORM_TOOGLE_LOADING";
/* <---- EVENTS ----> */

/* CONSULTSNOTIFICATION */
export const SET_CONSULTSNOTIFICATION_LIST = "SET_CONSULTSNOTIFICATION_LIST";
export const ADD_NEW_CONSULTSNOTIFICATION = "ADD_NEW_CONSULTSNOTIFICATION";
export const UPDATE_CONSULTSNOTIFICATION = "UPDATE_CONSULTSNOTIFICATION";
export const DELETE_CONSULTSNOTIFICATION = "DELETE_CONSULTSNOTIFICATION";
export const OPEN_CONSULTSNOTIFICATION_FORM = "OPEN_CONSULTSNOTIFICATION_FORM";
export const CLOSE_CONSULTSNOTIFICATION_FORM = "CLOSE_CONSULTSNOTIFICATION_FORM";
export const EDIT_SELECTED_CONSULTSNOTIFICATION = "EDIT_SELECTED_CONSULTSNOTIFICATION";
export const CONSULTSNOTIFICATION_FORM_TOOGLE_LOADING = "CONSULTSNOTIFICATION_FORM_TOOGLE_LOADING";
/* <---- CONSULTSNOTIFICATION ----> */

/* MEDICALCONSULTATIONS */
export const SET_MEDICALCONSULTATIONS_LIST = "SET_MEDICALCONSULTATIONS_LIST";
export const ADD_NEW_MEDICALCONSULTATIONS = "ADD_NEW_MEDICALCONSULTATIONS";
export const UPDATE_MEDICALCONSULTATIONS = "UPDATE_MEDICALCONSULTATIONS";
export const DELETE_MEDICALCONSULTATIONS = "DELETE_MEDICALCONSULTATIONS";
export const OPEN_MEDICALCONSULTATIONS_FORM = "OPEN_MEDICALCONSULTATIONS_FORM";
export const CLOSE_MEDICALCONSULTATIONS_FORM = "CLOSE_MEDICALCONSULTATIONS_FORM";
export const EDIT_SELECTED_MEDICALCONSULTATIONS = "EDIT_SELECTED_MEDICALCONSULTATIONS";
export const MEDICALCONSULTATIONS_FORM_TOOGLE_LOADING = "MEDICALCONSULTATIONS_FORM_TOOGLE_LOADING";
/* <---- MEDICALCONSULTATIONS ----> */

/* rooms */
export const SET_ROOMS_LIST = "SET_ROOMS_LIST";
export const ADD_NEW_ROOMS = "ADD_NEW_ROOMS";
export const UPDATE_ROOMS = "UPDATE_ROOMS";
export const DELETE_ROOMS = "DELETE_ROOMS";
export const OPEN_ROOMS_FORM = "OPEN_ROOMS_FORM";
export const CLOSE_ROOMS_FORM = "CLOSE_ROOMS_FORM";
export const EDIT_SELECTED_ROOMS = "EDIT_SELECTED_ROOMS";
export const ROOMS_FORM_TOOGLE_LOADING = "ROOMS_FORM_TOOGLE_LOADING";
/* <---- rooms ----> */

/* MEDICALAPPOINTMENT */
export const SET_MEDICALAPPOINTMENT_LIST = "SET_MEDICALAPPOINTMENT_LIST";
export const ADD_NEW_MEDICALAPPOINTMENT = "ADD_NEW_MEDICALAPPOINTMENT";
export const UPDATE_MEDICALAPPOINTMENT = "UPDATE_MEDICALAPPOINTMENT";
export const DELETE_MEDICALAPPOINTMENT = "DELETE_MEDICALAPPOINTMENT";
export const OPEN_MEDICALAPPOINTMENT_FORM = "OPEN_MEDICALAPPOINTMENT_FORM";
export const CLOSE_MEDICALAPPOINTMENT_FORM = "CLOSE_MEDICALAPPOINTMENT_FORM";
export const EDIT_SELECTED_MEDICALAPPOINTMENT = "EDIT_SELECTED_MEDICALAPPOINTMENT";
export const MEDICALAPPOINTMENT_FORM_TOOGLE_LOADING = "MEDICALAPPOINTMENT_FORM_TOOGLE_LOADING";
/* <---- MEDICALAPPOINTMENT ----> */

/* MEDICALCONSULATION */
export const SET_MEDICALCONSULATION_LIST = "SET_MEDICALCONSULATION_LIST";
export const ADD_NEW_MEDICALCONSULATION = "ADD_NEW_MEDICALCONSULATION";
export const UPDATE_MEDICALCONSULATION = "UPDATE_MEDICALCONSULATION";
export const DELETE_MEDICALCONSULATION = "DELETE_MEDICALCONSULATION";
export const OPEN_MEDICALCONSULATION_FORM = "OPEN_MEDICALCONSULATION_FORM";
export const CLOSE_MEDICALCONSULATION_FORM = "CLOSE_MEDICALCONSULATION_FORM";
export const EDIT_SELECTED_MEDICALCONSULATION = "EDIT_SELECTED_MEDICALCONSULATION";
export const MEDICALCONSULATION_FORM_TOOGLE_LOADING = "MEDICALCONSULATION_FORM_TOOGLE_LOADING";
/* <---- MEDICALCONSULATION ----> */

/* EMPLOYEE */
export const SET_EMPLOYEE_LIST = "SET_EMPLOYEE_LIST";
export const ADD_NEW_EMPLOYEE = "ADD_NEW_EMPLOYEE";
export const UPDATE_EMPLOYEE = "UPDATE_EMPLOYEE";
export const DELETE_EMPLOYEE = "DELETE_EMPLOYEE";
export const OPEN_EMPLOYEE_FORM = "OPEN_EMPLOYEE_FORM";
export const CLOSE_EMPLOYEE_FORM = "CLOSE_EMPLOYEE_FORM";
export const EDIT_SELECTED_EMPLOYEE = "EDIT_SELECTED_EMPLOYEE";
export const EMPLOYEE_FORM_TOOGLE_LOADING = "EMPLOYEE_FORM_TOOGLE_LOADING";
/* <---- EMPLOYEE ----> */

/* TYPEMEDICALCONSULTATION */
export const SET_TYPEMEDICALCONSULTATION_LIST = "SET_TYPEMEDICALCONSULTATION_LIST";
export const ADD_NEW_TYPEMEDICALCONSULTATION = "ADD_NEW_TYPEMEDICALCONSULTATION";
export const UPDATE_TYPEMEDICALCONSULTATION = "UPDATE_TYPEMEDICALCONSULTATION";
export const DELETE_TYPEMEDICALCONSULTATION = "DELETE_TYPEMEDICALCONSULTATION";
export const OPEN_TYPEMEDICALCONSULTATION_FORM = "OPEN_TYPEMEDICALCONSULTATION_FORM";
export const CLOSE_TYPEMEDICALCONSULTATION_FORM = "CLOSE_TYPEMEDICALCONSULTATION_FORM";
export const EDIT_SELECTED_TYPEMEDICALCONSULTATION = "EDIT_SELECTED_TYPEMEDICALCONSULTATION";
export const TYPEMEDICALCONSULTATION_FORM_TOOGLE_LOADING = "TYPEMEDICALCONSULTATION_FORM_TOOGLE_LOADING";
/* <---- TYPEMEDICALCONSULTATION ----> */

/* CLIENT */
export const SET_CLIENT_LIST = "SET_CLIENT_LIST";
export const ADD_NEW_CLIENT = "ADD_NEW_CLIENT";
export const UPDATE_CLIENT = "UPDATE_CLIENT";
export const DELETE_CLIENT = "DELETE_CLIENT";
export const OPEN_CLIENT_FORM = "OPEN_CLIENT_FORM";
export const CLOSE_CLIENT_FORM = "CLOSE_CLIENT_FORM";
export const EDIT_SELECTED_CLIENT = "EDIT_SELECTED_CLIENT";
export const CLIENT_FORM_TOOGLE_LOADING = "CLIENT_FORM_TOOGLE_LOADING";
/* <---- CLIENT ----> */

/* MEDICALHISTORY */
export const SET_MEDICALHISTORY_LIST = "SET_MEDICALHISTORY_LIST";
export const ADD_NEW_MEDICALHISTORY = "ADD_NEW_MEDICALHISTORY";
export const UPDATE_MEDICALHISTORY = "UPDATE_MEDICALHISTORY";
export const DELETE_MEDICALHISTORY = "DELETE_MEDICALHISTORY";
export const OPEN_MEDICALHISTORY_FORM = "OPEN_MEDICALHISTORY_FORM";
export const CLOSE_MEDICALHISTORY_FORM = "CLOSE_MEDICALHISTORY_FORM";
export const EDIT_SELECTED_MEDICALHISTORY = "EDIT_SELECTED_MEDICALHISTORY";
export const MEDICALHISTORY_FORM_TOOGLE_LOADING = "MEDICALHISTORY_FORM_TOOGLE_LOADING";
/* <---- MEDICALHISTORY ----> */

/* ROUTES */
export const SET_ALL_ROUTES = "SET_ALL_ROUTES";
export const UPDATE_ROUTE = "UPDATE_ROUTE";
/* <---- ROUTES ----> */

/* ALERT */
export const SET_ALERT_LIST = "SET_ALERT_LIST";
export const ADD_NEW_ALERT = "ADD_NEW_ALERT";
export const UPDATE_ALERT = "UPDATE_ALERT";
export const DELETE_ALERT = "DELETE_ALERT";
export const OPEN_ALERT_FORM = "OPEN_ALERT_FORM";
export const CLOSE_ALERT_FORM = "CLOSE_ALERT_FORM";
export const EDIT_SELECTED_ALERT = "EDIT_SELECTED_ALERT";
export const ALERT_FORM_TOOGLE_LOADING = "ALERT_FORM_TOOGLE_LOADING";
/* <---- ALERT ----> */
