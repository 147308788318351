import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_EVENTS,
    DELETE_EVENTS,
    EVENTS_FORM_TOOGLE_LOADING,
    SET_EVENTS_LIST,
    SHOW_NOTIFICATION,
    UPDATE_EVENTS,
} from "../constants";
import { formatEventss } from "./settingsActionsUtils";

/* EVENTS LIST */
export const fetchEventss = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.eventss.list)
            .then((response) => {
                const eventss = formatEventss(response.data);
                dispatch({
                    type: SET_EVENTS_LIST,
                    payload: keyBy(eventss, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// EVENTSS
export const createEvents = (newEvents) => {
    return async (dispatch) => {
        dispatch({ type: EVENTS_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.eventss.create, newEvents)
            .then((response) => {
                const events = formatEventss(response.data);
                dispatch({ type: ADD_NEW_EVENTS, payload: events });
                dispatch({ type: EVENTS_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Evento creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: EVENTS_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateEvents = (updatedEvents) => {
    return async (dispatch) => {
        dispatch({ type: EVENTS_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.eventss.edit}${updatedEvents && updatedEvents._id}`, updatedEvents)
            .then((response) => {
                const events = formatEventss(response.data);
                dispatch({ type: UPDATE_EVENTS, payload: events });
                dispatch({ type: EVENTS_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Evento actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: EVENTS_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteEventss = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: EVENTS_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.eventss.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_EVENTS, payload: Ids });
                dispatch({ type: EVENTS_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Evento eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: EVENTS_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
