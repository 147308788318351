import { combineReducers } from "redux";
import {
  authorization,
  profile,
  currentRoute,
  notifications,
  app,
  usersForm,
  users,
  alerts,
  alertsForm,
  currentTheme,
  MedicalHistorys,
  MedicalHistorysForm,
  clients,
  clientsForm,
  typeMedicalConsultations,
  typeMedicalConsultationsForm,
  employees,
  employeesForm,
  medicalConsultations,
  medicalConsultationsForm,
  room,
  roomForm,
  MedicalConsultationss,
  MedicalConsultationsForm,
  consultsNotifications,
  consultsNotificationsForm,
  eventss,
  eventssForm,
} from "./Reducers";

export default combineReducers({
  eventss,
  eventssForm,
  consultsNotifications,
  consultsNotificationsForm,
  MedicalConsultationss,
  MedicalConsultationsForm,
  room,
  roomForm,
  medicalConsultations,
  medicalConsultationsForm,
  employees,
  employeesForm,
  typeMedicalConsultations,
  typeMedicalConsultationsForm,
  clients,
  clientsForm,
  MedicalHistorys,
  MedicalHistorysForm,
  app,
  authorization,
  currentRoute,
  profile,
  notifications,
  usersForm,
  users,
  alerts,
  alertsForm,
  currentTheme,
});
