import { handleActions, handleAction } from "redux-actions";
import {
    AUTH,
    SET_PROFILE,
    SET_CURRENT_ROUTE,
    LOGOUT,
    SHOW_NOTIFICATION,
    CLOSE_NOTIFICATION,
    SET_SIDE_MENU_STATE,
    OPEN_USERS_FORM,
    CLOSE_USERS_FORM,
    EDIT_SELECTED_USER,
    USERS_FORM_TOOGLE_LOADING,
    SET_USER_LIST,
    ADD_NEW_USER,
    UPDATE_USER,
    DELETE_USER,
    OPEN_ALERT_FORM,
    CLOSE_ALERT_FORM,
    EDIT_SELECTED_ALERT,
    ALERT_FORM_TOOGLE_LOADING,
    SET_ALERT_LIST,
    ADD_NEW_ALERT,
    UPDATE_ALERT,
    DELETE_ALERT,
    THEME,
    ADD_NEW_MEDICALHISTORY,
    SET_MEDICALHISTORY_LIST,
    UPDATE_MEDICALHISTORY,
    DELETE_MEDICALHISTORY,
    OPEN_MEDICALHISTORY_FORM,
    CLOSE_MEDICALHISTORY_FORM,
    EDIT_SELECTED_MEDICALHISTORY,
    MEDICALHISTORY_FORM_TOOGLE_LOADING,
    ADD_NEW_CLIENT,
    SET_CLIENT_LIST,
    UPDATE_CLIENT,
    DELETE_CLIENT,
    OPEN_CLIENT_FORM,
    CLOSE_CLIENT_FORM,
    EDIT_SELECTED_CLIENT,
    CLIENT_FORM_TOOGLE_LOADING,
    ADD_NEW_TYPEMEDICALCONSULTATION,
    SET_TYPEMEDICALCONSULTATION_LIST,
    UPDATE_TYPEMEDICALCONSULTATION,
    DELETE_TYPEMEDICALCONSULTATION,
    OPEN_TYPEMEDICALCONSULTATION_FORM,
    CLOSE_TYPEMEDICALCONSULTATION_FORM,
    EDIT_SELECTED_TYPEMEDICALCONSULTATION,
    TYPEMEDICALCONSULTATION_FORM_TOOGLE_LOADING,
    ADD_NEW_EMPLOYEE,
    SET_EMPLOYEE_LIST,
    UPDATE_EMPLOYEE,
    DELETE_EMPLOYEE,
    OPEN_EMPLOYEE_FORM,
    CLOSE_EMPLOYEE_FORM,
    EDIT_SELECTED_EMPLOYEE,
    EMPLOYEE_FORM_TOOGLE_LOADING,
    ADD_NEW_MEDICALCONSULATION,
    SET_MEDICALCONSULATION_LIST,
    UPDATE_MEDICALCONSULATION,
    DELETE_MEDICALCONSULATION,
    OPEN_MEDICALCONSULATION_FORM,
    CLOSE_MEDICALCONSULATION_FORM,
    EDIT_SELECTED_MEDICALCONSULATION,
    MEDICALCONSULATION_FORM_TOOGLE_LOADING,
    ADD_NEW_ROOMS,
    SET_ROOMS_LIST,
    UPDATE_ROOMS,
    DELETE_ROOMS,
    OPEN_ROOMS_FORM,
    CLOSE_ROOMS_FORM,
    EDIT_SELECTED_ROOMS,
    ROOMS_FORM_TOOGLE_LOADING,
    ADD_NEW_MEDICALCONSULTATIONS,
    SET_MEDICALCONSULTATIONS_LIST,
    UPDATE_MEDICALCONSULTATIONS,
    DELETE_MEDICALCONSULTATIONS,
    OPEN_MEDICALCONSULTATIONS_FORM,
    CLOSE_MEDICALCONSULTATIONS_FORM,
    EDIT_SELECTED_MEDICALCONSULTATIONS,
    MEDICALCONSULTATIONS_FORM_TOOGLE_LOADING,
    ADD_NEW_CONSULTSNOTIFICATION,
    SET_CONSULTSNOTIFICATION_LIST,
    UPDATE_CONSULTSNOTIFICATION,
    DELETE_CONSULTSNOTIFICATION,
    OPEN_CONSULTSNOTIFICATION_FORM,
    CLOSE_CONSULTSNOTIFICATION_FORM,
    EDIT_SELECTED_CONSULTSNOTIFICATION,
    CONSULTSNOTIFICATION_FORM_TOOGLE_LOADING,
    ADD_NEW_EVENTS,
    SET_EVENTS_LIST,
    UPDATE_EVENTS,
    DELETE_EVENTS,
    OPEN_EVENTS_FORM,
    CLOSE_EVENTS_FORM,
    EDIT_SELECTED_EVENTS,
    EVENTS_FORM_TOOGLE_LOADING,
} from "./../../constants";

/* BASIC */
export const authorization = handleActions(
    {
        [AUTH]: (state, action) => action.payload,
        [LOGOUT]: () => ({ auth: false }),
    },
    null
);

/* PROFILE */
export const profile = handleActions(
    {
        [SET_PROFILE]: (state, action) => action.payload,
    },
    null
);

/* APP */
export const currentRoute = handleAction(
    SET_CURRENT_ROUTE,
    (state, action) => action.payload,
    {}
);

export const notifications = handleActions(
    {
        [SHOW_NOTIFICATION]: (state, action) => action.payload,
        [CLOSE_NOTIFICATION]: (state, action) => ({
            show: false,
            status: null,
            message: null,
        }),
    },
    { show: false, status: null, message: null }
);

/* APP */
export const app = handleActions(
    {
        [SET_SIDE_MENU_STATE]: (state, action) => {
            return { ...state, isCollapsedSideMenu: action.payload };
        },
    },
    { isCollapsedSideMenu: true }
);

export const currentTheme = handleAction(
    THEME,
    (state, action) => action.payload,
    false
);

export const users = handleActions(
    {
        [SET_USER_LIST]: (state, action) => action.payload,
        [ADD_NEW_USER]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [UPDATE_USER]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [DELETE_USER]: (state, action) => {
            action.payload.forEach((number) => {
                delete state[number];
            });
            return { ...state };
        },
    },
    null
);

export const usersForm = handleActions(
    {
        [OPEN_USERS_FORM]: (state) => ({
            show: !state.show,
            data: null,
            loading: false,
        }),
        [CLOSE_USERS_FORM]: () => ({ show: false, data: null, loading: false }),
        [EDIT_SELECTED_USER]: (s, action) => ({
            show: true,
            data: action.payload,
            loading: false,
        }),
        [USERS_FORM_TOOGLE_LOADING]: (state) => ({
            ...state,
            loading: !state.loading,
        }),
    },
    { show: false, data: null, loading: false }
);

export const alerts = handleActions(
    {
        [SET_ALERT_LIST]: (state, action) => action.payload,
        [ADD_NEW_ALERT]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [UPDATE_ALERT]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [DELETE_ALERT]: (state, action) => {
            action.payload.forEach((number) => {
                delete state[number];
            });
            return { ...state };
        },
    },
    null
);

export const alertsForm = handleActions(
    {
        [OPEN_ALERT_FORM]: (state) => ({
            show: !state.show,
            data: null,
            loading: false,
        }),
        [CLOSE_ALERT_FORM]: () => ({ show: false, data: null, loading: false }),
        [EDIT_SELECTED_ALERT]: (s, action) => ({
            show: true,
            data: action.payload,
            loading: false,
        }),
        [ALERT_FORM_TOOGLE_LOADING]: (state) => ({
            ...state,
            loading: !state.loading,
        }),
    },
    { show: false, data: null, loading: false }
);

// MEDICALHISTORYS
export const MedicalHistorys = handleActions(
    {
        [SET_MEDICALHISTORY_LIST]: (state, action) => action.payload,
        [ADD_NEW_MEDICALHISTORY]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [UPDATE_MEDICALHISTORY]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [DELETE_MEDICALHISTORY]: (state, action) => {
            action.payload.forEach((number) => {
                delete state[number];
            });
            return { ...state };
        },
    },
    null
);

export const MedicalHistorysForm = handleActions(
    {
        [OPEN_MEDICALHISTORY_FORM]: (state) => ({
            show: true,
            data: null,
            loading: false,
        }),
        [CLOSE_MEDICALHISTORY_FORM]: () => ({
            show: false,
            data: null,
            loading: false,
        }),
        [EDIT_SELECTED_MEDICALHISTORY]: (s, action) => ({
            show: true,
            data: action.payload,
            loading: false,
        }),
        [MEDICALHISTORY_FORM_TOOGLE_LOADING]: (state) => ({
            ...state,
            loading: !state.loading,
        }),
    },
    { show: false, data: null, loading: false }
);

// CLIENTS
export const clients = handleActions(
    {
        [SET_CLIENT_LIST]: (state, action) => action.payload,
        [ADD_NEW_CLIENT]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [UPDATE_CLIENT]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [DELETE_CLIENT]: (state, action) => {
            action.payload.forEach((number) => {
                delete state[number];
            });
            return { ...state };
        },
    },
    null
);

export const clientsForm = handleActions(
    {
        [OPEN_CLIENT_FORM]: (state) => ({
            show: !state.show,
            data: null,
            loading: false,
        }),
        [CLOSE_CLIENT_FORM]: () => ({
            show: false,
            data: null,
            loading: false,
        }),
        [EDIT_SELECTED_CLIENT]: (s, action) => ({
            show: true,
            data: action.payload,
            loading: false,
        }),
        [CLIENT_FORM_TOOGLE_LOADING]: (state) => ({
            ...state,
            loading: !state.loading,
        }),
    },
    { show: false, data: null, loading: false }
);

// TYPEMEDICALCONSULTATIONS
export const typeMedicalConsultations = handleActions(
    {
        [SET_TYPEMEDICALCONSULTATION_LIST]: (state, action) => action.payload,
        [ADD_NEW_TYPEMEDICALCONSULTATION]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [UPDATE_TYPEMEDICALCONSULTATION]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [DELETE_TYPEMEDICALCONSULTATION]: (state, action) => {
            action.payload.forEach((number) => {
                delete state[number];
            });
            return { ...state };
        },
    },
    null
);

export const typeMedicalConsultationsForm = handleActions(
    {
        [OPEN_TYPEMEDICALCONSULTATION_FORM]: (state) => ({
            show: !state.show,
            data: null,
            loading: false,
        }),
        [CLOSE_TYPEMEDICALCONSULTATION_FORM]: () => ({
            show: false,
            data: null,
            loading: false,
        }),
        [EDIT_SELECTED_TYPEMEDICALCONSULTATION]: (s, action) => ({
            show: true,
            data: action.payload,
            loading: false,
        }),
        [TYPEMEDICALCONSULTATION_FORM_TOOGLE_LOADING]: (state) => ({
            ...state,
            loading: !state.loading,
        }),
    },
    { show: false, data: null, loading: false }
);

// EMPLOYEES
export const employees = handleActions(
    {
        [SET_EMPLOYEE_LIST]: (state, action) => action.payload,
        [ADD_NEW_EMPLOYEE]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [UPDATE_EMPLOYEE]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [DELETE_EMPLOYEE]: (state, action) => {
            action.payload.forEach((number) => {
                delete state[number];
            });
            return { ...state };
        },
    },
    null
);

export const employeesForm = handleActions(
    {
        [OPEN_EMPLOYEE_FORM]: (state) => ({
            show: !state.show,
            data: null,
            loading: false,
        }),
        [CLOSE_EMPLOYEE_FORM]: () => ({
            show: false,
            data: null,
            loading: false,
        }),
        [EDIT_SELECTED_EMPLOYEE]: (s, action) => ({
            show: true,
            data: action.payload,
            loading: false,
        }),
        [EMPLOYEE_FORM_TOOGLE_LOADING]: (state) => ({
            ...state,
            loading: !state.loading,
        }),
    },
    { show: false, data: null, loading: false }
);

// MedicalConsultations
export const medicalConsultations = handleActions(
    {
        [SET_MEDICALCONSULATION_LIST]: (state, action) => action.payload,
        [ADD_NEW_MEDICALCONSULATION]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [UPDATE_MEDICALCONSULATION]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [DELETE_MEDICALCONSULATION]: (state, action) => {
            action.payload.forEach((number) => {
                delete state[number];
            });
            return { ...state };
        },
    },
    null
);

export const medicalConsultationsForm = handleActions(
    {
        [OPEN_MEDICALCONSULATION_FORM]: (state) => ({
            show: !state.show,
            data: null,
            loading: false,
        }),
        [CLOSE_MEDICALCONSULATION_FORM]: () => ({
            show: false,
            data: null,
            loading: false,
        }),
        [EDIT_SELECTED_MEDICALCONSULATION]: (s, action) => ({
            show: true,
            data: action.payload,
            loading: false,
        }),
        [MEDICALCONSULATION_FORM_TOOGLE_LOADING]: (state) => ({
            ...state,
            loading: !state.loading,
        }),
    },
    { show: false, data: null, loading: false }
);

// room
export const room = handleActions(
    {
        [SET_ROOMS_LIST]: (state, action) => action.payload,
        [ADD_NEW_ROOMS]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [UPDATE_ROOMS]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [DELETE_ROOMS]: (state, action) => {
            action.payload.forEach((number) => {
                delete state[number];
            });
            return { ...state };
        },
    },
    null
);

export const roomForm = handleActions(
    {
        [OPEN_ROOMS_FORM]: (state) => ({
            show: !state.show,
            data: null,
            loading: false,
        }),
        [CLOSE_ROOMS_FORM]: () => ({
            show: false,
            data: null,
            loading: false,
        }),
        [EDIT_SELECTED_ROOMS]: (s, action) => ({
            show: true,
            data: action.payload,
            loading: false,
        }),
        [ROOMS_FORM_TOOGLE_LOADING]: (state) => ({
            ...state,
            loading: !state.loading,
        }),
    },
    { show: false, data: null, loading: false }
);

// MEDICALCONSULTATIONSS
export const MedicalConsultationss = handleActions(
    {
        [SET_MEDICALCONSULTATIONS_LIST]: (state, action) => action.payload,
        [ADD_NEW_MEDICALCONSULTATIONS]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [UPDATE_MEDICALCONSULTATIONS]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [DELETE_MEDICALCONSULTATIONS]: (state, action) => {
            action.payload.forEach((number) => {
                delete state[number];
            });
            return { ...state };
        },
    },
    null
);

export const MedicalConsultationsForm = handleActions(
    {
        [OPEN_MEDICALCONSULTATIONS_FORM]: (state) => ({
            show: !state.show,
            data: null,
            loading: false,
        }),
        [CLOSE_MEDICALCONSULTATIONS_FORM]: () => ({
            show: false,
            data: null,
            loading: false,
        }),
        [EDIT_SELECTED_MEDICALCONSULTATIONS]: (s, action) => ({
            show: true,
            data: action.payload,
            loading: false,
        }),
        [MEDICALCONSULTATIONS_FORM_TOOGLE_LOADING]: (state) => ({
            ...state,
            loading: !state.loading,
        }),
    },
    { show: false, data: null, loading: false }
);

// CONSULTSNOTIFICATIONS
export const consultsNotifications = handleActions(
    {
        [SET_CONSULTSNOTIFICATION_LIST]: (state, action) => action.payload,
        [ADD_NEW_CONSULTSNOTIFICATION]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [UPDATE_CONSULTSNOTIFICATION]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [DELETE_CONSULTSNOTIFICATION]: (state, action) => {
            action.payload.forEach((number) => {
                delete state[number];
            });
            return { ...state };
        },
    },
    null
);

export const consultsNotificationsForm = handleActions(
    {
        [OPEN_CONSULTSNOTIFICATION_FORM]: (state) => ({
            show: !state.show,
            data: null,
            loading: false,
        }),
        [CLOSE_CONSULTSNOTIFICATION_FORM]: () => ({
            show: false,
            data: null,
            loading: false,
        }),
        [EDIT_SELECTED_CONSULTSNOTIFICATION]: (s, action) => ({
            show: true,
            data: action.payload,
            loading: false,
        }),
        [CONSULTSNOTIFICATION_FORM_TOOGLE_LOADING]: (state) => ({
            ...state,
            loading: !state.loading,
        }),
    },
    { show: false, data: null, loading: false }
);

// EVENTSS
export const eventss = handleActions(
    {
        [SET_EVENTS_LIST]: (state, action) => action.payload,
        [ADD_NEW_EVENTS]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [UPDATE_EVENTS]: (state, action) => {
            const newState = {
                ...state,
                [action.payload.value]: action.payload,
            };
            return newState;
        },
        [DELETE_EVENTS]: (state, action) => {
            action.payload.forEach((number) => {
                delete state[number];
            });
            return { ...state };
        },
    },
    null
);

export const eventssForm = handleActions(
    {
        [OPEN_EVENTS_FORM]: (state) => ({
            show: !state.show,
            data: null,
            loading: false,
        }),
        [CLOSE_EVENTS_FORM]: () => ({
            show: false,
            data: null,
            loading: false,
        }),
        [EDIT_SELECTED_EVENTS]: (s, action) => ({
            show: true,
            data: action.payload,
            loading: false,
        }),
        [EVENTS_FORM_TOOGLE_LOADING]: (state) => ({
            ...state,
            loading: !state.loading,
        }),
    },
    { show: false, data: null, loading: false }
);
